@tailwind base;
@tailwind components;
@tailwind utilities;

.otpcontainer {
	@apply flex justify-between gap-4 mt-8;
}
.otpcontainer input {
	@apply !mr-0 flex-1 outline-none;
}

::selection {
	background-color: #6f0606;
	color: #fffdfd;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
